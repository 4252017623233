import * as React from "react";
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './auth/authProvider';
import i18nProvider from './i18n/i18nProvider';
import users from './users';
import sessions from './sessions';
import casos from './casos';
import familiares from './familiares';
import lugares from './lugares';
import militancias from './militancias';
import webHome from './webHome';
import noticias from './noticias';
import recursos from './recursos';
import formularios from './formularios';
import multimedias from './multimedias';
import testimonios from './testimonios';
import Divider from '@mui/material/Divider';
import { CustomLayout } from './Layout';

const App = () => (
  <Admin
    disableTelemetry
    dataProvider={dataProvider}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    layout={CustomLayout}
  >
    {scope => [
      (scope === 'admin') ? [
        <Resource {...casos} />,
        <Resource {...familiares} />,
        <Resource {...lugares} />,
        <Resource {...militancias} />,
        <Resource {...webHome} />,
        <Resource {...noticias} />,
        <Resource {...recursos} />,
        <Resource {...formularios} />,
        <Resource {...multimedias} />,
        <Resource {...testimonios} />,
        <Resource name='familiarohermano' />
      ] : (scope === 'superadmin') ? [
        <Resource {...users} />,
        <Resource {...sessions} />
      ] : null
    ]}
  </Admin>
);
export default App;
