import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
  TextInput,
  Edit,
  Create,
  SimpleForm,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanField,
  DateField,
  useNotify,
  SelectInput,
  useRefresh,
  required,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';
import { useDispatch } from 'react-redux';
import ImageInput from '../ImageInput/ImageInput';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';
import TinyEditor from '../TinyEditor/TinyEditor';
import FileInput from '../FileInput/FileInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NoticiaPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const NoticiaList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'fecha', order: 'DESC' }}
      filters={<NoticiaFilter />}
      pagination={<NoticiaPagination />}
		  bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="Id" source="id" />
        <DateField label="Fecha" source="fecha" />
        <TextField label="Categoria" source="categoria" sortable={false} />
        <TextField label="Título" source="titulo" sortable={false} />
        <BooleanField label="Publicada" source="publicar" sortable={false} />
      </Datagrid>
    </List>
  );
};

export const NoticiaCreate = props => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const handleSubmit = async data => {
    const files = ['foto_home'];
    data.documentos?.forEach((doc, index) => {
			files.push(`documentos[${index}].url`);
		});
    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Create {...props} transform={handleSubmit} onFailure={onFailure} mutationMode='pessimistic'>
      <SimpleForm redirect="/noticia">
        <BooleanInput label="Publicar" source="publicar" defaultValue={false} />
        <DateInput label="Fecha" source="fecha" validate={[required()]} />
        <SelectInput label="Categoria" source="categoria" choices={Categorias} validate={[required()]} />
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Copete" source="copete" minRows={2} multiline fullWidth validate={[required()]} />
        <ImageInput name="foto_home" aspect={1.32} label="foto home" />
        <TinyEditor
          source='contenido'
          validate={[required()]}
        />
        <TextInput label="Fuente" source="fuente" validate={[required()]} />
        <TextInput label="Autor" source="autor" validate={[required()]} />
        <ReferenceArrayInput label="Casos" source="casos" reference="caso">
          <AutocompleteArrayInput optionText="nombre_caso" />
        </ReferenceArrayInput>
        <Accordion fullWidth>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="documentos-content"
            id="panel-documentos"
          >
            <Typography>ADJUNTAR DOCUMENTOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source='documentos' label={false}>
              <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                <TextInput style={{ width: '580px' }} label='Título' source="titulo" />
                <TextInput
                  label='Tipo'
                  source='tipo'
                  defaultValue={'archivo'}
                  disabled
                />
                <FileInput
                  source={'url'}
                  aspect={1.45}
                  label='documento'
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>
      </SimpleForm>
    </Create>
  );
}

export const NoticiaEdit = props => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };
  const handleSubmit = async data => {
    const files = ['foto_home'];
    data.documentos?.forEach((doc, index) => {
			files.push(`documentos[${index}].url`);
		});
    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Edit {...props} title={<NoticiaTitle />} onFailure={onFailure} onSuccess={onSuccess} mutationMode='pessimistic' transform={handleSubmit}>
      <SimpleForm redirect="/noticia">
        <BooleanInput label="Publicar" source="publicar" />
        <DateInput label="Fecha" source="fecha" validate={[required()]} />
        <SelectInput label="Categoria" source="categoria" choices={Categorias} validate={[required()]} />
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Copete" source="copete" minRows={2} multiline fullWidth validate={[required()]} />
        <ImageInput name="foto_home" aspect={1.32} label="foto home" />
        <TinyEditor
          source='contenido'
          validate={[required()]}
        />
        <TextInput label="Fuente" source="fuente" validate={[required()]} />
        <TextInput label="Autor" source="autor" validate={[required()]} />
        <ReferenceArrayInput label="Casos" source="casos" reference="caso">
          <AutocompleteArrayInput optionText="nombre_caso" />
        </ReferenceArrayInput>
        <Accordion fullWidth>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="documentos-content"
            id="panel-documentos"
          >
            <Typography>ADJUNTAR DOCUMENTOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source='documentos' label={false}>
              <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                <TextInput style={{ width: '580px' }} label='Título' source="titulo" />
                <TextInput
                  label='Tipo'
                  source='tipo'
                  defaultValue={'archivo'}
                  disabled
                />
                <FileInput
                  source={'url'}
                  aspect={1.45}
                  label='documento'
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>
      </SimpleForm>
    </Edit>
  );
}

const Categorias = [
  { id: 'Noticias', name: 'Noticias' },
  { id: 'Gacetillas', name: 'Gacetillas' },
  { id: 'Comunicados', name: 'Comunicados' }
];

const NoticiaFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
  </Filter>
);

const NoticiaTitle = ({ record }) => {
  return <span>Noticia: {`"${record.titulo}"`}</span>;
};