import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  maxLength
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { CustomMap } from '../customMap/customMap';

function LugarQuickCreateButton({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('lugar');
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    const { ccd, nombre, provincia, geoposicion } = values;
    create(
      { payload: { data: { ccd, nombre, provincia, geoposicion } } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change('id_lugar_nacimiento', data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        }
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create lugar"
      >
        <DialogTitle>Agregar lugar</DialogTitle>

        <FormWithRedirect
          resource="lugar"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <BooleanInput label="¿Es CCD?" source="ccd" />
                <TextInput
                  source="nombre"
                  validate={[required(), maxLength()]}
                  fullWidth
                />
                <BooleanInput label="¿Otro país?" source="otro_pais" />
                <FormDataConsumer>
                  {({ formData, ...rest }) => (!formData.otro_pais &&
                    <SelectInput 
                      style={{ width: '260px' }} 
                      label="Provincia" 
                      source="provincia" 
                      choices={Provincia} 
                      validate={required()}
                    />
                  )}
                </FormDataConsumer>
                <CustomMap
                  name="geoposicion"
                  size="large"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

const Provincia = [
  { id: "Buenos Aires", name: "Buenos Aires" },
  { id: "CABA", name: "CABA" },
  { id: "Catamarca", name: "Catamarca" },
  { id: "Chaco", name: "Chaco" },
  { id: "Chubut", name: "Chubut" },
  { id: "Córdoba", name: "Córdoba" },
  { id: "Corrientes", name: "Corrientes" },
  { id: "Entre Ríos", name: "Entre Ríos" },
  { id: "Formosa", name: "Formosa" },
  { id: "Jujuy", name: "Jujuy" },
  { id: "La Pampa", name: "La Pampa" },
  { id: "La Rioja", name: "La Rioja" },
  { id: "Mendoza", name: "Mendoza" },
  { id: "Misiones", name: "Misiones" },
  { id: "Neuquén", name: "Neuquén" },
  { id: "Río Negro", name: "Río Negro" },
  { id: "Salta", name: "Salta" },
  { id: "San Juan", name: "San Juan" },
  { id: "San Luis", name: "San Luis" },
  { id: "Santa Cruz", name: "Santa Cruz" },
  { id: "Santa Fe", name: "Santa Fe" },
  { id: "Santiago del Estero", name: "Santiago del Estero" },
  { id: "Tierra del Fuego", name: "Tierra del Fuego" },
  { id: "Tucumán", name: "Tucumán" },
];

export default LugarQuickCreateButton;
