import React, { useState, useCallback } from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import MilitanciaQuickCreateButton from './MilitanciaQuickCreateButton';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center'
  }
});

const MilitanciaReferenceArrayInput = props => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceArrayInput key={version} {...props}>
        <AutocompleteArrayInput optionText={"nombre"} />
      </ReferenceArrayInput>

      <MilitanciaQuickCreateButton onChange={handleChange} />
    </div>
  );
};

export default MilitanciaReferenceArrayInput;
