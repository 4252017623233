import { HomeCreate } from './webHome';
import HomeIcon from '@mui/icons-material/Home';

const index = {
    create: HomeCreate,
    // edit: HomeEdit,
    icon: HomeIcon,
    options: { label: 'Web Home' },
    name: "home",
};

export default index;