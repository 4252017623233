import React, { useRef } from 'react';
import { useInput } from 'react-admin';
import { Editor } from '@tinymce/tinymce-react';
import inMemoryJWT from '../auth/inMemoryJWT';

export default function TinyEditor(props) {
  const editorRef = useRef(null);
  const name = props.name || props.source;
  const { input, meta: { touched, error }, isRequired } = useInput(props);
  const content = input.value || '';

  const onChange = value => {
    if (editorRef.current)
      input.onChange(editorRef.current.getContent());
  }

  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      onInit={(evt, editor) => editorRef.current = editor}
      value={content}
      onEditorChange={(value, editor) => onChange(value)}
      init={{
        height: 500,
        menubar: false,
        language: 'es',
        language_url: 'es.js',
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'charmap',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'image', 'table', 'preview', 'help', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | link image media | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | preview | code | fullscreen',
        fullscreen_native: true,
        images_upload_handler: uploadHandler,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.addEventListener('change', async (e) => {
            const file = e.target.files[0];
            const srcFile = window.URL.createObjectURL(file);
            cb(srcFile, { title: file.name });
          });

          input.click();
        },
        content_style: 'body {font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  )
};

const uploadHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', `${process.env.REACT_APP_API_ENDPOINT}/api/archivo`);
  const token = inMemoryJWT.getToken();
  xhr.setRequestHeader('Authorization', token);
  
  xhr.upload.onprogress = (e) => {
    progress(e.loaded / e.total * 100);
  };

  xhr.onload = () => {
    if (xhr.status === 403) {
      reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      reject('HTTP Error: ' + xhr.status);
      return;
    }

    const json = JSON.parse(xhr.responseText);

    if (!json || typeof json.url != 'string') {
      reject('Invalid JSON: ' + xhr.responseText);
      return;
    }

    resolve(json.url);
  };

  xhr.onerror = () => {
    reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  const formData = new FormData();
  formData.append('archivo', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
});
