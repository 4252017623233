import React, { Fragment } from 'react';
import {
	List,
	TextInput,
	Datagrid,
	TextField,
	Filter,
	Pagination,
	Create,
	Edit,
	BulkDeleteButton,
	SelectInput,
	DateInput,
	NumberInput,
	BooleanInput,
	FormDataConsumer,
	TabbedForm,
	FormTab,
	useNotify,
	useRefresh,
	required,
	ArrayInput,
	SimpleFormIterator,
	maxLength
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Grid } from '@mui/material';
import Relations from './Relations';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageInput from '../ImageInput/ImageInput';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';
import { useDispatch } from 'react-redux';
import FileInput from '../FileInput/FileInput';
import _ from 'lodash';
import LugarReferenceInput from '../lugares/LugarReferenceInput';
import CcdReferenceArrayInput from '../lugares/CcdReferenceArrayInput';

const CasoPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

const CasoBulkActionButtons = props => (
	<Fragment>
		<BulkDeleteButton {...props} />
	</Fragment>
);

export const CasoList = props => (
	<List
		{...props}
		bulkActionButtons={false}
		pagination={<CasoPagination />}
		filters={<CasoFilter />}>
		<Datagrid rowClick="edit">
			<TextField label="Nombre del caso" source="nombre_caso" />
			<TextField label="Tipo de caso" source="tipo_caso" />
			<TextField label="Estado del caso" source="estado_caso" />
			<TextField label="Estado" source="estado" />
		</Datagrid>
	</List>
);

export const CasoCreate = props => {
	const notify = useNotify();
	const dispatch = useDispatch();

	const handleSubmit = data => {
		parseDateRange(data);
		delete data.familiar;
		delete data.parentesco;
		delete data.changed;
		delete data.familiarObject;
		if (data.familiares) {
			data.familiares = data.familiares.map(familiar => {
				if (familiar.isCaso)
					return { id: familiar.id, parentesco: familiar.parentesco, isCaso: true };

				return { id: familiar.id, parentesco: familiar.parentesco }
			});
		}

		if (!data.web)
			return data;

		const files = [
			'web.imagen_url',
			'web.hero.caso_imagen_url',
			'web.hero.caso_imagen_url_mobile',
			'web.desarrollo.desarrollo_imagen_url',
			'web.foto_uno.foto_uno_imagen_url',
			'web.foto_dos.foto_dos_imagen_url',
			'web.conferencia_restitucion.conferencia_imagen_url',
			'web.linea_de_tiempo.linea_de_tiempo_imagen_url',
		];

		data.web.bloque_documentos?.documentos.forEach((doc, index) => {
			files.push(`web.bloque_documentos.documentos[${index}].url`);
		});
		data.web.bloque_multimedia_uno?.multimedias.forEach((doc, index) => {
			files.push(`web.bloque_multimedia_uno.multimedias[${index}].url`);
		});
		data.web.bloque_multimedia_dos?.multimedias.forEach((doc, index) => {
			files.push(`web.bloque_multimedia_dos.multimedias[${index}].url`);
		});

		return handleSubmitFiles({ data, dispatch, notify, files });
	};

	return (
		<Create {...props} transform={handleSubmit}>
			<Formulario redirect={'/caso'} />
		</Create>
	)
};

export const CasoEdit = props => {
	const notify = useNotify();
	const refresh = useRefresh();
	const dispatch = useDispatch();

	const onFailure = (error) => {
		notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
	};
	const onSuccess = () => {
		notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
		refresh();
	};

	const handleSubmit = data => {
		parseDateRange(data);
		delete data.familiar;
		delete data.parentesco;
		delete data.changed;
		delete data.familiarObject;
		if (data.familiares) {
			data.familiares = data.familiares.map(familiar => {
				if (familiar.isCaso)
					return { id: familiar.id, parentesco: familiar.parentesco, isCaso: true };

				return { id: familiar.id, parentesco: familiar.parentesco }
			});
		}

		if (!data.web)
			return data;

		const files = [
			'web.imagen_url',
			'web.hero.caso_imagen_url',
			'web.hero.caso_imagen_url_mobile',
			'web.desarrollo.desarrollo_imagen_url',
			'web.foto_uno.foto_uno_imagen_url',
			'web.foto_dos.foto_dos_imagen_url',
			'web.conferencia_restitucion.conferencia_imagen_url',
			'web.linea_de_tiempo.linea_de_tiempo_imagen_url',
		];

		data.web.bloque_documentos?.documentos.forEach((doc, index) => {
			files.push(`web.bloque_documentos.documentos[${index}].url`);
		});
		data.web.bloque_multimedia_uno?.multimedias.forEach((doc, index) => {
			files.push(`web.bloque_multimedia_uno.multimedias[${index}].url`);
		});
		data.web.bloque_multimedia_dos?.multimedias.forEach((doc, index) => {
			files.push(`web.bloque_multimedia_dos.multimedias[${index}].url`);
		});

		return handleSubmitFiles({ data, dispatch, notify, files });
	};

	return (
		<Edit {...props} onFailure={onFailure} onSuccess={onSuccess} mutationMode='pessimistic' title={<CasoTitle />} transform={handleSubmit}>
			<Formulario />
		</Edit>
	);
};

const CasoFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Buscar" source="q" alwaysOn />
	</Filter>
);

const parseDateRange = data => {
	const datesRanges = [
		'fecha_nacimiento',
		'fecha_localizacion',
		'fecha_restitucion',
		'fecha_bndg',
		'secuestro.fecha_secuestro',
		'secuestro.fecha_liberacion',
		'asesinato.fecha_asesinato',
		'asesinato.fecha_eaaf',
		'fecha_fallecimiento'
	];
	datesRanges.forEach(dateRange => {
		const range = _.get(data, dateRange);
		if (range && Array.isArray(range) && (range[0] === "" || range[1] === ""))
			_.set(data, dateRange, null);
	})
};

const EstadoCaso = [
	{ id: 'Restituidx', name: 'Restituidx' },
	{ id: 'Asesinadx', name: 'Asesinadx' },
	{ id: 'No nacidx', name: 'No nacidx' },
	{ id: 'Busqueda', name: 'Sigue la Busqueda' }
];

const Sexo = [
	{ id: 'M', name: 'M' },
	{ id: 'F', name: 'F' },
	{ id: 'No se conoce', name: 'No se conoce' }
];

const Estado = [
	{ id: 'Desaparecidx', name: 'Desaparecidx' },
	{ id: 'Asesinadx', name: 'Asesinadx' },
	{ id: 'Identificadx por EAAF', name: 'Identificadx por EAAF' },
	{ id: 'Vivx', name: 'Vivx' },
	{ id: 'Fallecidx', name: 'Fallecidx' },
];

const TipoCaso = [
	{ id: 'DJP', name: 'DJP' },
	{ id: 'NC', name: 'Nacidx en Cautiverio' }
];

const TipoLugar = [
	{ id: 'Vía pública', name: 'Vía pública' },
	{ id: 'Domicilio', name: 'Domicilio' },
	{ id: 'Trabajo', name: 'Trabajo' },
];

const TipoDocumento = [
	{ id: 'imagen', name: 'Imágen' },
	{ id: 'video', name: 'Video' },
	{ id: 'archivo', name: 'Archivo' },
	{ id: 'audio', name: 'Audio' },
];

const TipoApropiacion = [
	{ id: '(A) Inscripto como hijx propix', name: '(A) Inscripto como hijx propix' },
	{ id: '(A) A través de Adopción o guarda', name: '(A) A través de Adopción o guarda' },
	{ id: '(NA) No fue apropiación', name: '(NA) No fue apropiación' },
];

const TipoResolucion = [
	{ id: 'Presentación Espontánea', name: 'Presentación Espontánea' },
	{ id: 'Aproximación', name: 'Aproximación' },
	{ id: 'Judicial ADN', name: 'Judicial ADN' },
	{ id: 'Judicial Allanamiento', name: 'Judicial Allanamiento' },
	{ id: 'Comision Gub. E.V', name: 'Comision Gub. E.V' }
];

const CasoTitle = ({ record }) => {
	return <span>Caso {record?.nombre_caso ? `"${record.nombre_caso}"` : ''}</span>;
};

const validateCaso = values => {
	const errors = { web: { hero: {} } };

	if (values.web?.visible) {
		if (!values.web.imagen_url)
			errors.web.visible = 'La imágen del caso es requerida';
		else if (!values.web.hero?.caso_imagen_url)
			errors.web.visible = 'En INICIO: La imágen es requerida';
	}

	return errors;
};

const Formulario = (props) => (
	<TabbedForm {...props} validate={validateCaso} warnWhenUnsavedChanges={true}>
		<FormTab label="Caso">
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid item xs={12} md={4}>
							<TextInput fullWidth label="Nombre del caso" source="nombre_caso" />
						</Grid>
						<Grid item xs={12} md={8}>
							<SelectInput style={{ marginRight: '8px' }} label="Tipo de caso" source="tipo_caso" choices={TipoCaso} allowEmpty />
							<SelectInput style={{ marginRight: '8px' }} label="Estado del caso" source="estado_caso" choices={EstadoCaso} allowEmpty />
							<SelectInput label="Estado" source="estado" choices={Estado} allowEmpty />
						</Grid>
						<Grid item xs={12}>
							<TextInput style={{ marginRight: '8px' }} label="Apellidos" source="apellidos" />
							<TextInput style={{ marginRight: '8px' }} label="Nombres" source="nombres" />
							<SelectInput style={{ marginRight: '8px' }} label="Sexo" source="sexo" choices={Sexo} />
						</Grid>
						{formData.estado_caso !== 'NoN' &&
							<>
								<Grid item xs={12} md={3}>
									<LugarReferenceInput
										label="Lugar de Nacimiento"
										source="id_lugar_nacimiento"
										reference="lugar"
										allowEmpty
										fullWidth
									/>
								</Grid>
								<Grid item xs={9}>
									<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de nacimiento. Desde:" source="fecha_nacimiento[0]" />
									<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de nacimiento. Hasta:" source="fecha_nacimiento[1]" />
								</Grid>
							</>
						}
						{formData.tipo_caso === 'NC' &&
							<Grid item xs={3}>
								<BooleanInput label="¿Existe testimonio?" source="existe_testimonio" />
							</Grid>}
						<Grid item xs={9}>
							{formData.existe_testimonio && <TextInput fullWidth label="Testimonio" source="testimonio" multiline />}
						</Grid>
						{formData.estado_caso === 'Restituidx' && <>
							<Grid item xs={12}>
								<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de localizacion. Desde:" source="fecha_localizacion[0]" />
								<DateInput parse={value => value} style={{ width: '240px' }} label="Fecha de localizacion. Hasta:" source="fecha_localizacion[1]" />
							</Grid>
							<Grid item xs={12}>
								<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de restitucion. Desde:" source="fecha_restitucion[0]" />
								<DateInput parse={value => value} style={{ width: '240px' }} label="Fecha de restitucion. Hasta:" source="fecha_restitucion[1]" />
							</Grid>
							<Grid item xs={12}>
								<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de bndg. Desde:" source="fecha_bndg[0]" />
								<DateInput parse={value => value} style={{ width: '240px' }} label="Fecha de bndg. Hasta:" source="fecha_bndg[1]" />
							</Grid>
							<Grid item xs={12}>
								<SelectInput style={{ width: '240px', marginRight: '8px' }} label="Tipo de apropiacion" source="tipo_apropiacion" choices={TipoApropiacion} />
								<SelectInput style={{ width: '240px' }} label="Tipo de resolucion" source="tipo_resolucion" choices={TipoResolucion} />
							</Grid>
						</>}
						<Grid item xs={12}>
							<NumberInput style={{ marginRight: '8px' }} label="Número de nieto" source="numero_nieto" />
							{formData.estado === 'Fallecidx' &&
								<>
									<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de fallecimiento. Desde:" source="fecha_fallecimiento[0]" />
									<DateInput parse={value => value} style={{ width: '240px' }} label="Fecha de fallecimiento. Hasta:" source="fecha_fallecimiento[1]" />
								</>
							}
						</Grid>
					</Grid>
				)}
			</FormDataConsumer>
		</FormTab>

		<FormDataConsumer>
			{({ formData, ...rest }) => ((formData.tipo_caso === 'DJP') ?
				<FormTab {...rest} label="Secuestro" path="secuestro">
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid item xs={12} md={3}>
							<LugarReferenceInput
								label="Lugar de Secuestro"
								source="secuestro.id_lugar"
								reference="lugar"
								allowEmpty
								fullWidth
							/>
						</Grid>
						<Grid item xs={9}>
							<SelectInput style={{ marginRight: '8px' }} label="Tipo de lugar" source="secuestro.tipo_lugar" choices={TipoLugar} />
						</Grid>
						<Grid item xs={12}>
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de secuestro. Desde:" source="secuestro.fecha_secuestro[0]" />
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de secuestro. Hasta:" source="secuestro.fecha_secuestro[1]" />
						</Grid>
						<Grid item xs={12}>
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de liberación. Desde:" source="secuestro.fecha_liberacion[0]" />
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de liberación. Hasta:" source="secuestro.fecha_liberacion[1]" />
						</Grid>
						<Grid item xs={12}>
							<CcdReferenceArrayInput
								label="CCDs"
								source="secuestro.ccds"
								reference="lugar"
							/>
						</Grid>
					</Grid>
				</FormTab>
				:
				<Grid></Grid>
			)}
		</FormDataConsumer>

		<FormDataConsumer>
			{({ formData, ...rest }) => ((formData.estado === 'Asesinadx' || formData.estado_caso === 'Asesinadx') ?
				<FormTab {...rest} label="Asesinato" path="asesinato">
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid item xs={12} md={3}>
							<LugarReferenceInput
								label="Lugar de asesinato"
								source="asesinato.id_lugar_asesinato"
								reference="lugar"
								allowEmpty
								fullWidth
							/>
						</Grid>
						<Grid item xs={9}>
							<SelectInput style={{ marginRight: '8px' }} label="Tipo de lugar" source="asesinato.tipo_lugar" choices={TipoLugar} />
						</Grid>
						<Grid item xs={12}>
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de asesinato. Desde:" source="asesinato.fecha_asesinato[0]" />
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de asesinato. Hasta:" source="asesinato.fecha_asesinato[1]" />
						</Grid>
						<Grid item xs={12}>
							<DateInput style={{ width: '240px', marginRight: '8px' }} label="Fecha EAAF. Desde:" source="asesinato.fecha_eaaf[0]" />
							<DateInput style={{ width: '240px', marginRight: '8px' }} label="Fecha EAAF. Hasta:" source="asesinato.fecha_eaaf[1]" />
						</Grid>
						<Grid item xs={12} md={3}>
							<LugarReferenceInput
								label="Lugar de entierro"
								source="asesinato.id_lugar_entierro"
								reference="lugar"
								allowEmpty
								fullWidth
							/>
						</Grid>
					</Grid>
				</FormTab>
				:
				<Grid></Grid>
			)}
		</FormDataConsumer>

		<FormTab label="Familiares" path="familiar">
			<Relations name="familiares" />
		</FormTab>

		<FormTab label="Web" path="web">
			<FormDataConsumer>
				{({ formData, ...rest }) => ((
					<>
						<Grid item xs={12}>
							<BooleanInput label="¿Publicar caso?" source="web.visible" />
						</Grid>
						<ImageInput
							name="web.imagen_url"
							label="imagen del caso"
							aspect={1}
						/>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelhero-content"
								id="panel-hero"
							>
								<Typography>ENCABEZADO</Typography>
								{/* <InfoIcon style={{marginLeft: '10px'}} color='info' onClick={() => alert('ups')}/> */}
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<TextInput disabled source="nombre_caso" label="Nombre del Caso" style={{ width: '420px', marginRight: '8px' }} />
										<TextInput disabled source="estado_caso" label="Estado del Caso" />
									</Grid>
									<Grid item xs={12}>
										<TextInput
											source="web.hero.caso_frase"
											label="Cita Encabezado"
											multiline
											minRows={2}
											validate={maxLength(255)}
											fullWidth />
									</Grid>
									<Grid item xs={12}>
										<ImageInput
											name="web.hero.caso_imagen_url"
											aspect={1.85}
											label="imagen web"
										/>
									</Grid>
									<Grid item xs={12}>
										<ImageInput
											name="web.hero.caso_imagen_url_mobile"
											aspect={0.56}
											label="imagen celular"
										/>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>

						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelintro-content"
								id="panel-intro"
							>
								<Typography>RELATO INICIAL</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<TextInput disabled source="nombre_caso" label="Nombre del Caso" style={{ width: '420px' }} />
									</Grid>
									<Grid item xs={12}>
										<TextInput
											source="web.intro.caso_titulo"
											label="Título Relato Inicial"
											validate={maxLength(255)}
											fullWidth
										/>
									</Grid>
									{formData.estado_caso === "Restituidx" &&
										<>
											<Grid item xs={12}>
												<DateInput disabled parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de bndg. Desde:" source="fecha_bndg[0]" />
												<DateInput disabled parse={value => value} style={{ width: '240px' }} label="Fecha de bndg. Hasta:" source="fecha_bndg[1]" />
											</Grid>
											<Grid item xs={12}>
												<DateInput disabled parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de localizacion. Desde:" source="fecha_localizacion[0]" />
												<DateInput disabled parse={value => value} style={{ width: '240px' }} label="Fecha de localizacion. Hasta:" source="fecha_localizacion[1]" />
											</Grid>
											<Grid item xs={12}>
												<DateInput disabled parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de restitucion. Desde:" source="fecha_restitucion[0]" />
												<DateInput disabled parse={value => value} style={{ width: '240px' }} label="Fecha de restitucion. Hasta:" source="fecha_restitucion[1]" />
											</Grid>
										</>
									}
									<Grid item xs={12}>
										<TextInput
											source="web.intro.caso_descripcion"
											label="Descripción"
											multiline
											minRows={3}
											validate={maxLength(2000)}
											fullWidth />
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>

						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="paneldesarrollo-content"
								id="panel-desarrollo"
							>
								<Typography>RELATO PRINCIPAL</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<RichTextInput
											source="web.desarrollo.desarrollo_descripcion"
											label="Texto Relato Principal"
											validate={[maxLength(2000)]}
											fullWidth />
									</Grid>
									<Grid item xs={12}>
										<ImageInput
											name="web.desarrollo.desarrollo_imagen_url"
											aspect={1}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextInput
											source="web.desarrollo.desarrollo_imagen_pie"
											label="Pie de foto"
											validate={maxLength(255)}
											fullWidth />
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelmapa-content"
								id="panel-mapa"
							>
								<Typography>LUGARES RELEVANTES DE LA HISTORIA FAMILIAR</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<ArrayInput source='web.web_lugares' label={false}>
									<SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
										<TextInput
											source="titulo"
											label='Título'
											style={{ width: '580px' }}
											validate={[required(), maxLength(255)]}
										/>
										<TextInput
											source="color"
											label="Color"
											style={{ maxWidth: '60px' }}
											validate={required()}
											type="color"
										/>
										<LugarReferenceInput
											label="Lugar"
											source="id_lugar"
											reference="lugar"
											allowEmpty
											validate={required()}
										/>
									</SimpleFormIterator>
								</ArrayInput>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelfotouno-content"
								id="panel-fotouno"
							>
								<Typography>PRIMER SEPARADOR CON FOTO</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.foto_uno.foto_uno_visible" />
									</Grid>
									{formData.web?.foto_uno?.foto_uno_visible &&
										<>
											<Grid item xs={12}>
												<ImageInput
													name="web.foto_uno.foto_uno_imagen_url"
													aspect={2.89}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextInput
													source="web.foto_uno.foto_uno_imagen_pie"
													label="Pie de foto"
													validate={maxLength(255)}
													fullWidth />
											</Grid>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelmultimediauno-content"
								id="panel-multimediauno"
							>
								<Typography>BLOQUE DE VIDEOS</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.bloque_multimedia_uno.multimedia_uno_visible" />
									</Grid>
									{formData.web?.bloque_multimedia_uno?.multimedia_uno_visible &&
										<>
											<Grid item xs={12}>
												<TextInput
													source="web.bloque_multimedia_uno.multimedia_uno_texto"
													label="Texto"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
											<Grid item xs={12}>
												<TextInput
													source="web.bloque_multimedia_uno.multimedia_uno_firma"
													label="Firma"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
											<ArrayInput source='web.bloque_multimedia_uno.multimedias' label={false}>
												<SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
													<TextInput style={{ width: '580px' }} label='Título' source="titulo" />
													<TextInput
														label='Tipo'
														source='tipo'
														defaultValue={'video'}
														disabled
													/>
													<TextInput
														style={{ width: '580px' }}
														label='URL del video'
														validate={[required(), maxLength(255)]}
														source='url'
													/>
												</SimpleFormIterator>
											</ArrayInput>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="paneltextouno-content"
								id="panel-textouno"
							>
								<Typography>RELATO SECUNDARIO</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.bloque_texto_uno.texto_uno_visible" />
									</Grid>
									{formData.web?.bloque_texto_uno?.texto_uno_visible &&
										<>
											<Grid item xs={12}>
												<RichTextInput
													source="web.bloque_texto_uno.texto_uno_texto"
													label="Texto Relato Secundario"
													validate={[required(), maxLength(2000)]}
													fullWidth />
											</Grid>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelfotodos-content"
								id="panel-fotodos"
							>
								<Typography>SEGUNDO SEPARADOR CON FOTO</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.foto_dos.foto_dos_visible" />
									</Grid>
									{formData.web?.foto_dos?.foto_dos_visible &&
										<>
											<Grid item xs={12}>
												<ImageInput
													name="web.foto_dos.foto_dos_imagen_url"
													aspect={2.89}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextInput
													source="web.foto_dos.foto_dos_imagen_pie"
													label="Pie de foto"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="paneltextodos-content"
								id="panel-textodos"
							>
								<Typography>SEGUNDO BLOQUE DE TEXTO</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.bloque_texto_dos.texto_dos_visible" />
									</Grid>
									{formData.web?.bloque_texto_dos?.texto_dos_visible &&
										<>
											<Grid item xs={12}>
												<RichTextInput
													source="web.bloque_texto_dos.texto_dos_texto"
													label="Texto"
													validate={[required(), maxLength(2000)]}
													fullWidth />
											</Grid>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelmultimediados-content"
								id="panel-multimediados"
							>
								<Typography>BLOQUE DE IMÁGENES</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.bloque_multimedia_dos.multimedia_dos_visible" />
									</Grid>
									{formData.web?.bloque_multimedia_dos?.multimedia_dos_visible &&
										<>
											<Grid item xs={12}>
												<TextInput
													source="web.bloque_multimedia_dos.multimedia_dos_texto"
													label="Texto"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
											<Grid item xs={12}>
												<TextInput
													source="web.bloque_multimedia_dos.multimedia_dos_firma"
													label="Firma"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
											<ArrayInput source='web.bloque_multimedia_dos.multimedias' label={false}>
												<SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
													<TextInput 
														style={{ width: '580px' }} 
														label='Título' 
														validate={[required(), maxLength(255)]}
														source="titulo" 
													/>
													<TextInput
														label='Tipo'
														source='tipo'
														defaultValue={'imagen'}
														disabled
													/>
													<ImageInput
														source='url'
														label='imagen'
														aspect={1.45}
														validate={[required()]}
													/>
												</SimpleFormIterator>
											</ArrayInput>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelfrase-content"
								id="panel-frase"
							>
								<Typography>FRASE</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.frase.frase_visible" />
									</Grid>
									{formData.web?.frase?.frase_visible &&
										<>
											<Grid item xs={12}>
												<TextInput
													source="web.frase.frase_texto"
													label="Texto"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
											<Grid item xs={12}>
												<TextInput
													source="web.frase.frase_autor"
													label="Autor"
													validate={[required(), maxLength(255)]}
													fullWidth />
											</Grid>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
						{formData.estado_caso === "Restituidx" && <>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelconfrestitucion-content"
									id="panel-confrestitucion"
								>
									<Typography>CONFERENCIA DE RESTITUCION</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<BooleanInput label="¿Visible?" source="web.conferencia_restitucion.visible" />
										</Grid>
										{formData.web?.conferencia_restitucion?.visible && <>
											<Grid item xs={12}>
												<ImageInput
													name="web.conferencia_restitucion.conferencia_imagen_url"
													aspect={1.95}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextInput
													source="web.conferencia_restitucion.conferencia_video_url"
													validate={[maxLength(255)]}
													label="Video (URL)"
													fullWidth />
											</Grid>
										</>}
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panellineadetiempo-content"
									id="panel-lineadetiempo"
								>
									<Typography>LINEA DE TIEMPO</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<ImageInput
												name="web.linea_de_tiempo.linea_de_tiempo_imagen_url"
												aspect={1.46}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web.linea_de_tiempo.linea_de_tiempo_descripcion"
												label="Descripción"
												validate={[maxLength(255)]}
												fullWidth />
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</>}
						<Accordion fullWidth>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="paneldocumentos-content"
								id="panel-documentos"
							>
								<Typography>DOCUMENTOS</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={1}
								>
									<Grid item xs={12}>
										<BooleanInput label="¿Visible?" source="web.bloque_documentos.documentos_visible" />
									</Grid>
									{formData.web?.bloque_documentos?.documentos_visible &&
										<>
											<ArrayInput source='web.bloque_documentos.documentos' label={false}>
												<SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
													<TextInput style={{ width: '580px' }} label='Título' source="titulo" />
													<SelectInput label="Tipo" source="tipo" choices={TipoDocumento} />
													<FormDataConsumer>
														{({ getSource, scopedFormData }) => {
															if (!scopedFormData) return null;
															const { tipo } = scopedFormData;
															return ((tipo === 'imagen') ?
																<ImageInput
																	name={getSource('url')}
																	record={scopedFormData}
																/>
																: (tipo === 'archivo') ?
																	<FileInput
																		name={getSource('url')}
																		record={scopedFormData}
																	/>
																	:
																	<TextInput
																		style={{ width: '580px' }}
																		label='URL'
																		source={getSource('url')}
																	/>
															);
														}}
													</FormDataConsumer>
												</SimpleFormIterator>
											</ArrayInput>
										</>}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</>
				))}
			</FormDataConsumer>
		</FormTab>
	</TabbedForm >
);