import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Pagination,
  TextInput,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  useNotify,
  useRefresh,
  required,
  FormDataConsumer,
  BooleanField,
  BooleanInput,
  DateInput
} from 'react-admin';
import { useDispatch } from 'react-redux';
import ImageInput from '../ImageInput/ImageInput';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';
import FileInput from '../FileInput/FileInput';
import { Grid } from '@mui/material';


const MultimediaPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const MultimediaList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<MultimediaFilter />}
      pagination={<MultimediaPagination />}
		  bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        {/* <TextField label="Id" source="id" /> */}
        <TextField label="Título" source="titulo" sortable={false} />
        <TextField label="Categoria" source="categoria" sortable={true} />
        <TextField label="Tipo" source="tipo" sortable={false} />
        <DateField label="Fecha" source="fecha" sortable={true} />
        <BooleanField label="Publicada" source="publicar" sortable={false} />
      </Datagrid>
    </List>
  );
};

export const MultimediaCreate = props => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const handleSubmit = async data => {
    const files = [
			'thumbnail'
    ]
    if (data.tipo === 'archivo') {
      files.push('url');
    }

    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Create {...props} transform={handleSubmit} onFailure={onFailure} mutationMode='pessimistic'>
      <SimpleForm redirect="/multimedia">
        <BooleanInput label="Publicar" source="publicar" defaultValue={false} />
        <Grid item xs={4} lg={2}>
          <DateInput label="Fecha" source="fecha" fullWidth validate={[required()]} />
        </Grid>
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Descripción" source="descripcion" minRows={2} multiline fullWidth />
        <SelectInput
          label='Categoria'
          source='categoria'
          choices={CategoriaMultimedia}
          validate={[required()]}
        />
        <SelectInput
          label='Tipo'
          source='tipo'
          choices={TipoMultimedia}
          validate={[required()]}
        />
        <FormDataConsumer>
				{({ formData, ...rest }) => (formData.tipo === 'archivo' ? 
          <FileInput
            source={'url'}
            label='archivo'
            validate={[required()]}
          />
          :  
          <TextInput
            source={'url'}
            label='URL'
            validate={[required()]}
          />
        )}
        </FormDataConsumer>
        <ImageInput 
          name="thumbnail" 
          aspect={1} 
          label="Imágen miniatura" 
        />
      </SimpleForm>
    </Create>
  );
}

export const MultimediaEdit = props => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };
  const handleSubmit = async data => {
    const files = [
			'thumbnail'
    ]
    if (data.tipo === 'archivo') {
      files.push('url');
    }

    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Edit {...props} title={<MultimediaTitle />} onFailure={onFailure} onSuccess={onSuccess} mutationMode='pessimistic' transform={handleSubmit}>
      <SimpleForm>
        <BooleanInput label="Publicar" source="publicar"/>
        <Grid item xs={4} lg={2}>
          <DateInput label="Fecha" source="fecha" fullWidth validate={[required()]} />
        </Grid>
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Descripción" source="descripcion" minRows={2} multiline fullWidth />
        <SelectInput
          label='Categoria'
          source='categoria'
          choices={CategoriaMultimedia}
          validate={[required()]}
        />
        <SelectInput
          label='Tipo'
          source='tipo'
          choices={TipoMultimedia}
          validate={[required()]}
        />
        <FormDataConsumer>
				{({ formData, ...rest }) => (formData.tipo === 'archivo' ? 
          <FileInput
            source={'url'}
            label='archivo'
            validate={[required()]}
          />
          :  
          <TextInput
            source={'url'}
            label='URL'
            validate={[required()]}
          />
        )}
        </FormDataConsumer>
        <ImageInput 
          name="thumbnail" 
          aspect={1} 
          label="Imágen miniatura" 
        />
      </SimpleForm>
    </Edit>
  );
}

const TipoMultimedia = [
  { id: 'video', name: 'Video' },
  { id: 'archivo', name: 'Archivo' },
  { id: 'imagen', name: 'Imágen' },
  { id: 'audio', name: 'Audio' }
];

const MultimediaFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
    <SelectInput label="Categoria" source="categoria" choices={CategoriaMultimedia} alwaysOn />
  </Filter>
);

const MultimediaTitle = ({ record }) => {
  return <span>Multimedia: {`"${record.titulo}"`}</span>;
};

const CategoriaMultimedia = [
  {id: "publicidades", name: "Publicidades"},
  {id: "videos", name: "Videos"},
  {id: "audios", name: "Audios"},
  {id: "imagenes", name: "Imágenes"},
  {id: "publicaciones", name: "Publicaciones"},
  {id: "mensuario", name: "Mensuario"}
]