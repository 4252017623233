import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  DateField,
  SelectField
} from 'react-admin';

const FormularioPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const FormularioList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<FormularioFilter />}
      pagination={<FormularioPagination />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        {/* <TextField label="Id" source="id" /> */}
        <DateField label="Fecha y hora" showTime source="created_at" sortable={false} />
        <TextField label="Tipo de formulario" source="tipo_formulario" sortable={false} />
        <TextField label="Remitente" source="remitente" sortable={false} />
        <TextField label="Asunto" source="asunto" sortable={false} />
      </Datagrid>
    </List>
  );
};

export const FormularioEdit = props => {
  return (
    <Edit {...props} title={<FormularioTitle />} >
      <SimpleForm toolbar={<></>} >
        <DateField label="Fecha y hora" showTime source="created_at" />
        <SelectField disabled label="Tipo de formulario" source="tipo_formulario" choices={TipoFormulario} />
        <TextField label="Remitente" source="remitente" />
        <TextField label="Asunto" source="asunto" />
        <TextField label="Cuerpo" source="cuerpo" />
      </SimpleForm>
    </Edit>
  );
}


const TipoFormulario = [
  { id: 'dudo-de-mi-origen', name: 'Dudo de mi origen' },
  { id: 'quiero-aportar-informacion', name: 'Quiero aportar información' },
];

const FormularioFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
    <SelectInput label="Tipo de formulario" source="tipo_formulario" choices={TipoFormulario} alwaysOn />
  </Filter>
);

const FormularioTitle = ({ record }) => {
  return <span>Formulario: {`"${record.asunto || ''}"`}</span>;
};