import { FormularioList, FormularioEdit } from './formularios';
import NewspaperIcon from '@mui/icons-material/SpeakerNotes';

const index = {
  list: FormularioList,
  edit: FormularioEdit,
  icon: NewspaperIcon,
  options: { label: 'Formularios' },
  name: "formulario",
};

export default index;