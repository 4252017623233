import { RecursoList, RecursoCreate, RecursoEdit } from './recursos';
import NewspaperIcon from '@mui/icons-material/GridView';

const index = {
  list: RecursoList,
  create: RecursoCreate,
  edit: RecursoEdit,
  icon: NewspaperIcon,
  options: { label: 'Recursos' },
  name: "recurso",
};

export default index;