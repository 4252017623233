import PersonIcon from '@mui/icons-material/Person';
import { CasoCreate, CasoEdit, CasoList } from './casos';

const index = {
  list: CasoList,
  icon: PersonIcon,
  create: CasoCreate,
  edit: CasoEdit,
  options: { label: 'Casos' },
  name: "caso",
};

export default index;
