import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useField } from 'react-final-form';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const SearchField = (props) => {
  const provider = new OpenStreetMapProvider();

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar',
    searchLabel: 'Ingresa una dirección',
    notFoundMessage: 'Lo sentimos, no encontramos esa dirección',
    showMarker: false
  });

  const map = useMap();
  useEffect(() => {
    map.addControl(searchControl);
    map.on('geosearch/showlocation', props.onChange);
    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

function DraggableMarker(props) {
  const position = props.position;
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          let latLng = marker.getLatLng();
          props.onChange([latLng.lat, latLng.lng]);
        }
      },
    }),
    [],
  );

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}>
    </Marker>
  )
}

export const CustomMap = props => {
  const { name, size } = props;
  const { input } = useField(name);
  const geoposicion = input.value;
  const [center, setCenter] = useState({ lat: geoposicion[0] || -37, lng: geoposicion[1] || -65 });
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
      setInterval(function () {
        map.invalidateSize(); // solucion para el mal renderizado.
      }, 100);
    }
  }, [map]);

  const onChangeMarker = latLng => {
    if (latLng)
      input.onChange(latLng);
  };

  const onChangeSearch = ({ location }) => {
    setCenter({ lat: location.y, lng: location.x });
    input.onChange([location.y, location.x]);
  };

  return (
    <Grid container style={{marginBottom: '30px'}}>
      <Grid item xs={!size || size === 'medium' ? 6 : size === 'small' ? 4 : 12}>
        <Card style={{ padding: '6px' }}>
          <MapContainer whenCreated={setMap} style={{ heigth: 'auto', width: 'auto' }} zoomControl={false} center={center} zoom={4} fullWidth>
            <SearchField onChange={onChangeSearch} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker
              onChange={onChangeMarker}
              position={geoposicion || { lat: -37, lng: -65 }}
            />
          </MapContainer>
        </Card>
      </Grid>
    </Grid>
  );
};

CustomMap.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  name: PropTypes.string.isRequired,
};