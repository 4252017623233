import { fetchUtils } from 'react-admin';
import inMemoryJWT from './inMemoryJWT';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = inMemoryJWT.getToken();
    if (token) {
        options.headers.set('Authorization', `${token}`);
        return fetchUtils.fetchJson(url,options);
    } else {
        inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT_AUTH+'/api/refresh');
        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                options.headers.set('Authorization', `${inMemoryJWT.getToken()}`);
            };
            return fetchUtils.fetchJson(url, options);
        });
    }
};

export default httpClient;
