import { MilitanciaList, MilitanciaCreate, MilitanciaEdit } from './militancias';
import VpnKeyIcon from '@material-ui/icons/Flag';

const index = {
  list: MilitanciaList,
  create: MilitanciaCreate,
  edit: MilitanciaEdit,
  icon: VpnKeyIcon,
  options: { label: 'Militancias' },
  name: "militancia",
};

export default index;