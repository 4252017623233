import React, { useState, useCallback } from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import LugarQuickCreateButton from './LugarQuickCreateButton';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center'
  }
});

const CcdReferenceArrayInput = props => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      <ReferenceArrayInput key={version} {...props} sort={{field: 'nombre', order: 'ASC'}}>
        <AutocompleteArrayInput optionText={record => record?.provincia ? `${record.nombre}, ${record.provincia}` : record?.nombre ? `${record.nombre}` : ''} />
      </ReferenceArrayInput>

      <LugarQuickCreateButton onChange={handleChange} />
    </div>
  );
};

export default CcdReferenceArrayInput;
