import React from 'react';
import { TableRow, TableHead, Table, TableBody } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, TableCell, Button } from '@mui/material';
import {
  CreateButton,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  SelectInput,
  CheckboxGroupInput,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

const Parentesco = [
  { id: 'Madre', name: 'Madre' },
  { id: 'Padre', name: 'Padre' },
  { id: 'Abuela P', name: 'Abuela P' },
  { id: 'Abuela M', name: 'Abuela M' },
  { id: 'Abuelo P', name: 'Abuelo P' },
  { id: 'Abuelo M', name: 'Abuelo M' },
  { id: 'Hermanx', name: 'Hermanx' },
  { id: 'Tix', name: 'Tix' }
];

const Relations = (props) => {
  const { input } = useField(props);
  const { familiares } = input.value;

  const desvincular = (value) => {
    const newFamiliares = familiares.filter(e => e.id !== value);
    const newInput = { ...input.value, changed: !input.value.changed, familiares: [...newFamiliares] };
    input.onChange(newInput);
  };

  const vincular = () => {
    const { parentesco, familiarObject } = input.value;
    if (familiarObject.hasOwnProperty('nombre_caso')) {
      familiarObject.isCaso = true;
    }

    const oldFamiliares = input.value.familiares;
    if (oldFamiliares && oldFamiliares.some(f => (f.id === familiarObject.id && familiarObject.hasOwnProperty('isCaso') === f.hasOwnProperty('isCaso')))) {
      const newInput = { ...input.value, familiar: null, parentesco: null };
      input.onChange(newInput);
      alert("Ese familiar ya está vinculado");
      return;
    }
    const familiares = [{ ...familiarObject, parentesco }];
    if (oldFamiliares)
      familiares.push(...oldFamiliares);
    const newInput = { ...input.value, familiar: null, parentesco: null, changed: !input.value.changed, familiares };
    input.onChange(newInput);
  };

  const handleAddFamiliar = record => {
    input.value.familiarObject = record;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {['Parentesco', 'Apellidos', 'Nombres', 'Estado', ''].map(c => (
              <TableCell key={c}>{c}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {familiares?.map(familiar => (
            <TableRow>
              <TableCell key={`${familiar.id}-parentesco`}>{familiar.parentesco}</TableCell>
              <TableCell key={`${familiar.id}-apellidos`}>{familiar.apellidos || familiar.nombre_caso}</TableCell>
              <TableCell key={`${familiar.id}-nombres`}>{familiar.nombres}</TableCell>
              <TableCell key={`${familiar.id}-estado`}>{familiar.estado}</TableCell>
              <TableCell>
                <EditButton basePath={familiar.isCaso ? '/caso' : '/familiar'} record={familiar} />
              </TableCell>
              <TableCell>
                <Button onClick={() => desvincular(familiar.id)} variant="text" color="error" startIcon={<DeleteIcon />}>Desvincular</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        style={{ margin: '2px' }}
      >
        <Grid item xs={2}>
          <SelectInput style={{ marginRight: '8px' }} source="parentesco" label="Parentesco" choices={Parentesco} fullWidth />
        </Grid>

        <Grid item xs={3}>
          {input.value.parentesco === 'Hermanx' ?
            <ReferenceInput label="Familiar" source="familiar" reference="familiarohermano" perPage={5} fullWidth allowEmpty>
              <AutocompleteInput optionText={record => record && record.apellidos ? `${record.apellidos}, ${record.nombres}` : record?.nombre_caso ? record.nombre_caso : ''} onSelect={handleAddFamiliar} />
            </ReferenceInput>
            :
            <ReferenceInput label="Familiar" source="familiar" reference="familiar" perPage={10} fullWidth allowEmpty>
              <AutocompleteInput optionText={record => record && record.apellidos ? `${record.apellidos}, ${record.nombres}` : ''} onSelect={handleAddFamiliar} />
            </ReferenceInput>
          }
        </Grid>
        <Grid item xs={6}>
          <CreateButton disabled={!input.value.parentesco || !input.value.familiar} label="vincular" onClick={(e) => { e.preventDefault(); vincular() }} />
        </Grid>
        <CheckboxGroupInput label='' source="changed" type='hidden'></CheckboxGroupInput>
        <CheckboxGroupInput label='' source="familiarObject" type='hidden'></CheckboxGroupInput>
      </Grid>
    </>
  )
};

Relations.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default Relations;