import jwt_decode from "jwt-decode";
import inMemoryJWT from './inMemoryJWT';

const authUrl = process.env.REACT_APP_API_ENDPOINT_AUTH + '/api'
const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(authUrl + '/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      // headers: new Headers({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      // credentials: process.env.NODE_ENV === 'development' ? 'omit' : 'include',
    });
    inMemoryJWT.setRefreshTokenEndpoint(authUrl + '/refresh');
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        let token = access_token;
        if (token) {
          let delay = jwt_decode(access_token);
          inMemoryJWT.setToken(token, delay.exp);
          return true;
        }
        return true;

      });
  },

  logout: () => {
    const request = new Request(authUrl + '/logout', {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      // credentials: process.env.NODE_ENV === 'development' ? 'omit' : 'include',
    });
    inMemoryJWT.ereaseToken();
    return fetch(request).then(() => '/login');
  },

  // called when the API returns an error
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      inMemoryJWT.ereaseToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    if (!inMemoryJWT.getToken()) {
      inMemoryJWT.setRefreshTokenEndpoint(authUrl + '/refresh');
      return inMemoryJWT.getRefreshedToken().then(tokenHasBeenRefreshed => {
        return tokenHasBeenRefreshed ? Promise.resolve() : Promise.reject();
      });
    } else {
      return Promise.resolve();
    }
  },

  // called when the user navigates to a new location, to check for permissions / permissions
  getPermissions: () => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      let token = inMemoryJWT.getToken();
      if (token) {
        token = jwt_decode(token);
      }
      return token.scope ? Promise.resolve(token.scope) : Promise.reject();
    });
  },
};

export default authProvider;