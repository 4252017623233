import GroupsIcon from '@mui/icons-material/Groups';
import { FamiliarCreate, FamiliarEdit, FamiliarList } from './familiares';

const index = {
  list: FamiliarList,
  icon: GroupsIcon,
  create: FamiliarCreate,
  edit: FamiliarEdit,
  options: { label: 'Familiares' },
  name: "familiar",
};

export default index;
