import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
  TextInput,
  Edit,
  Create,
  SimpleForm,
  required,
  maxLength
} from 'react-admin';

const SessionPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;


export const MilitanciaList = props => {
  return (
    <List
      {...props}
		  filters={<MilitanciaFilter />}
      pagination={<SessionPagination />}
		  bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nombre" source="nombre" />
      </Datagrid>
    </List>
  );
};

export const MilitanciaCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/militancia">
      <TextInput label="Nombre" validate={[required(), maxLength(255)]} source="nombre" />
    </SimpleForm>
  </Create>
);

export const MilitanciaEdit = props => (
  <Edit {...props} title={<MilitanciaTitle />} mutationMode='pessimistic'>
    <SimpleForm redirect="/militancia">
      <TextInput label="Nombre" validate={[required(), maxLength(255)]} source="nombre" />
    </SimpleForm>
  </Edit>
);

const MilitanciaFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Buscar" source="q" alwaysOn />
	</Filter>
);

const MilitanciaTitle = ({ record }) => {
	return <span>Militancia {`"${record.nombre}"`}</span>;
};