import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanInput,
  Pagination,
  TextInput,
  Edit,
  Create,
  SimpleForm,
  useNotify,
  useRefresh,
  required,
  BooleanField,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';
import ImageInput from '../ImageInput/ImageInput';

const TestimonioPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const TestimonioList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<TestimonioPagination />}
		  bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        {/* <TextField label="Id" source="id" /> */}
        <BooleanField label="Publicada" source="publicar" sortable={false} />
        <TextField label="Título" source="titulo" sortable={false} />
        <TextField label="Url" source="url" sortable={false} />
      </Datagrid>
    </List>
  );
};

export const TestimonioCreate = props => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const handleSubmit = async data => {
    const files = ['imagen'];
    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Create {...props} transform={handleSubmit} onFailure={onFailure} mutationMode='pessimistic'>
      <SimpleForm redirect="/testimonio">
        <BooleanInput label="Publicar" source="publicar" defaultValue={false} />
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Descripción" source="descripcion" validate={[required()]} minRows={2} multiline fullWidth />
        <ImageInput name="imagen" aspect={1.32} label="foto testimonio" />
        <TextInput source={'url'} label='URL Testimonio' />
        <ReferenceArrayInput label="Casos" source="casos" reference="caso">
          <AutocompleteArrayInput optionText="nombre_caso" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

export const TestimonioEdit = props => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };
  const handleSubmit = async data => {
    const files = ['imagen'];
    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Edit {...props} title={<TestimonioTitle />} onFailure={onFailure} onSuccess={onSuccess} mutationMode='pessimistic' transform={handleSubmit}>
      <SimpleForm>
        <BooleanInput label="Publicar" source="publicar" />
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Descripción" source="descripcion" validate={[required()]} minRows={2} multiline fullWidth />
        <ImageInput name="imagen" aspect={1.32} label="foto testimonio" />
        <TextInput source={'url'} label='URL Testimonio' />
        <ReferenceArrayInput label="Casos" source="casos" reference="caso">
          <AutocompleteArrayInput optionText="nombre_caso" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
}

const TestimonioTitle = ({ record }) => {
  return <span>Testimonio: {`"${record.titulo}"`}</span>;
};
