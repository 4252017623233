import { Grid } from '@mui/material';
import React from 'react';
import {
  TextInput,
  Create,
  NumberInput,
  SimpleForm,
  useGetOne,
  Loading,
  useNotify,
  required
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';

import ImageInput from '../ImageInput/ImageInput';

export const HomeCreate = props => {
  const { data, loading, error } = useGetOne('home', 0);

  const dispatch = useDispatch();
  const notify = useNotify();

  const handleSubmit = async data => {
    delete data.id;
    const files = ['hero_imagen_url'];
    return handleSubmitFiles({data, dispatch, notify, files});
  }

  if (loading) { return <Loading />; }

  return (
    <Create {...props} title={<HomeTitle />} successMessage='Elemento guardado' transform={handleSubmit}>
      <SimpleForm redirect="/home/create" initialValues={data}>
        <TextInput label="Título" source="hero_accion_titulo" />
        <TextInput label="Acción URL" source="hero_accion_url" />
        <TextInput label="Video URL" source="hero_video_url" />
        <NumberInput validate={[required()]} label="Número de nietxs restituidxs" source="nietos_restituidos" />
        <ImageInput 
          name="hero_imagen_url" 
          label="Hero imágen" 
          aspect={1.85}
        />
      </SimpleForm>
    </Create>
  );
};

const HomeTitle = ({ record }) => {
  return <span>Web Home</span>;
};