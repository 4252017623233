import { LugarList, LugarCreate, LugarEdit } from './lugares';
import VpnKeyIcon from '@material-ui/icons/LocationOn';

const index = {
    list: LugarList,
    create: LugarCreate,
    edit: LugarEdit,
    icon: VpnKeyIcon,
    options: { label: 'Lugares' },
    name: "lugar",
};

export default index;