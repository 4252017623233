import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  Edit,
  Create,
  SimpleForm,
  FormDataConsumer,
  BooleanInput,
  required,
  maxLength
} from 'react-admin';

import { CustomMap } from '../customMap/customMap';

const SessionPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const LugarList = props => {
  return (
    <List
      {...props}
      filters={<LugarFilter />}
      pagination={<SessionPagination />}
		  bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nombre" source="nombre" />
        <TextField label="Provincia" source="provincia" />
      </Datagrid>
    </List>
  );
};

export const LugarCreate = props => (
  <Create {...props} transform={handleSubmit}>
    <SimpleForm redirect="/lugar">
      <BooleanInput label="¿Es un CCD?" source="ccd" />
      <TextInput 
        label="Nombre" 
        source="nombre" 
        validate={required()}
      />
      <BooleanInput label="¿Otro país?" source="otro_pais" />
      <FormDataConsumer>
				{({ formData, ...rest }) => ( !formData.otro_pais &&
          <SelectInput 
            style={{ width: '260px'}} 
            label="Provincia" 
            source="provincia" 
            choices={Provincia} 
            validate={required()}
          />
        )}
      </FormDataConsumer>
      <CustomMap name="geoposicion" />
    </SimpleForm>
  </Create>
);

export const LugarEdit = props => (
  <Edit {...props} title={<LugarTitle />} mutationMode='pessimistic' transform={handleSubmit}>
    <SimpleForm redirect="/lugar">
      <BooleanInput label="¿Es un CCD?" source="ccd" />
      <TextInput 
        label="Nombre" 
        source="nombre" 
        validate={[required(), maxLength()]}
      />
      <FormDataConsumer>
				{({ formData, ...rest }) => (formData.provincia && 
          <SelectInput 
            style={{ width: '260px'}} 
            label="Provincia" 
            source="provincia" 
            choices={Provincia} 
            fullWidth
            validate={required()}
          />
        )}
      </FormDataConsumer>
      <CustomMap name="geoposicion" />
    </SimpleForm>
  </Edit>
);

const LugarFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
  </Filter>
);

const LugarTitle = ({ record }) => {
  return <span>Lugar {`"${record.nombre}, ${record.provincia}"`}</span>;
};

const handleSubmit = data => {
  delete data.otro_pais;
  return data;
};

const Provincia = [
  { id: "Buenos Aires", name: "Buenos Aires" },
  { id: "CABA", name: "CABA" },
  { id: "Catamarca", name: "Catamarca" },
  { id: "Chaco", name: "Chaco" },
  { id: "Chubut", name: "Chubut" },
  { id: "Córdoba", name: "Córdoba" },
  { id: "Corrientes", name: "Corrientes" },
  { id: "Entre Ríos", name: "Entre Ríos" },
  { id: "Formosa", name: "Formosa" },
  { id: "Jujuy", name: "Jujuy" },
  { id: "La Pampa", name: "La Pampa" },
  { id: "La Rioja", name: "La Rioja" },
  { id: "Mendoza", name: "Mendoza" },
  { id: "Misiones", name: "Misiones" },
  { id: "Neuquén", name: "Neuquén" },
  { id: "Río Negro", name: "Río Negro" },
  { id: "Salta", name: "Salta" },
  { id: "San Juan", name: "San Juan" },
  { id: "San Luis", name: "San Luis" },
  { id: "Santa Cruz", name: "Santa Cruz" },
  { id: "Santa Fe", name: "Santa Fe" },
  { id: "Santiago del Estero", name: "Santiago del Estero" },
  { id: "Tierra del Fuego", name: "Tierra del Fuego" },
  { id: "Tucumán", name: "Tucumán" },
];

