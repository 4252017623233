import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
  TextInput,
  Edit,
  Create,
  SimpleForm,
  BooleanInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  BooleanField,
  DateField,
  useNotify,
  SelectInput,
  useRefresh,
  required,
  DateTimeInput
} from 'react-admin';
import { useDispatch } from 'react-redux';
import ImageInput from '../ImageInput/ImageInput';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';
import TinyEditor from '../TinyEditor/TinyEditor';
import { Grid } from '@mui/material';
import FileInput from '../FileInput/FileInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RecursoPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const RecursoList = props => {
  return (
    <List
      {...props}
      sort={{ field: 'fecha_hora', order: 'DESC' }}
      filters={<RecursoFilter />}
      pagination={<RecursoPagination />}
		  bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField label="Id" source="id" />
        <DateField label="Fecha" source="fecha_hora" />
        <TextField label="Tipo de recurso" source="tipo_recurso" sortable={false} />
        <TextField label="Título" source="titulo" sortable={false} />
        <BooleanField label="Publicada" source="publicar" sortable={false} />
        <BooleanField label="Destacada" source="destacar" sortable={false} />
      </Datagrid>
    </List>
  );
};

const jsonValidation = (value, allValues) => {
  if (!value) {
    return 'Contenido es requerido';
  }

  try {
    JSON.parse(value);
  } catch (error) {
    return error.message;
  }

  return undefined;
};

export const RecursoCreate = props => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const handleSubmit = async data => {
    if (data.tipo_recurso !== 'Evento')
      data.fecha_hora = data.fecha_hora.substring(0, 10) + 'T03:00:00.000Z';

    const files = ['foto_home'];
    data.documentos?.forEach((doc, index) => {
			files.push(`documentos[${index}].url`);
		});
    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Create {...props} transform={handleSubmit} onFailure={onFailure} mutationMode='pessimistic'>
      <SimpleForm redirect="/recurso">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={4} lg={2} >
            <BooleanInput label="Publicar" source="publicar" defaultValue={false} />
          </Grid>
          <Grid item xs={8} lg={10}>
            <BooleanInput label="Destacar" source="destacar" defaultValue={false} />
          </Grid>
          <Grid item xs={12}>
            <SelectInput style={{marginRight: '20px'}} label="Tipo de recurso" source="tipo_recurso" choices={TipoRecurso} validate={[required()]} />
            <FormDataConsumer>
              {({ formData, ...rest }) => ((formData.tipo_recurso === 'Educacion') ? <>
                <SelectInput style={{marginRight: '20px'}} label="Categoria" source="categoria_educacion" choices={TipoCategoria} validate={[required()]} />
                <DateInput style={{marginRight: '20px'}} label="Fecha" source="fecha_hora" validate={[required()]} />
              </>
              : formData.tipo_recurso === 'Evento' ?
                <DateTimeInput style={{marginRight: '20px'}} label="Fecha y hora" source="fecha_hora" validate={[required()]} />
              : 
                <DateInput style={{marginRight: '20px'}} label="Fecha" source="fecha_hora" validate={[required()]} />
              )}
            </FormDataConsumer>
          </Grid>
        </Grid>
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Copete" source="copete" minRows={2} multiline fullWidth validate={[required()]} />
        <ImageInput name="foto_home" aspect={1.32} label="foto home" />
        <FormDataConsumer>
				  {({ formData, ...rest }) => ((formData.tipo_recurso === 'Red_Derecho_Identidad' ?
            <TextInput 
              label="Contenido"
              source="contenido" 
              style={{overflow: "auto", height: "400px"}} 
              multiline 
              fullWidth
              validate={jsonValidation}             
            />
          :
            <TinyEditor
              source='contenido'
              validate={[required()]}
            />
          ))}  
        </FormDataConsumer>
        <TextInput label="Fuente" source="fuente" validate={[required()]} />
        <TextInput label="Autor" source="autor" validate={[required()]} />
        <Accordion fullWidth>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="documentos-content"
            id="panel-documentos"
          >
            <Typography>ADJUNTAR DOCUMENTOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source='documentos' label={false}>
              <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                <TextInput style={{ width: '580px' }} label='Título' source="titulo" />
                <TextInput
                  label='Tipo'
                  source='tipo'
                  defaultValue={'archivo'}
                  disabled
                />
                <FileInput
                  source={'url'}
                  aspect={1.45}
                  label='documento'
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>
      </SimpleForm>
    </Create>
  );
}

export const RecursoEdit = props => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
  };
  const onSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    refresh();
  };
  const handleSubmit = async data => {
    if (data.tipo_recurso !== 'Evento')
      data.fecha_hora = data.fecha_hora.substring(0, 10) + 'T03:00:00.000Z';
    
    const files = ['foto_home'];
    data.documentos?.forEach((doc, index) => {
			files.push(`documentos[${index}].url`);
		});
    return handleSubmitFiles({ data, dispatch, notify, files });
  }

  return (
    <Edit {...props} title={<RecursoTitle />} onFailure={onFailure} onSuccess={onSuccess} mutationMode='pessimistic' transform={handleSubmit}>
      <SimpleForm redirect="/recurso">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={4} lg={2}>
            <BooleanInput label="Publicar" source="publicar" />
          </Grid>
          <Grid item xs={8} lg={10}>
            <BooleanInput label="Destacar" source="destacar" />
          </Grid>
          <Grid item xs={12}>
            <SelectInput style={{marginRight: '20px'}} label="Tipo de recurso" source="tipo_recurso" choices={TipoRecurso} validate={[required()]} />
            <FormDataConsumer>
              {({ formData, ...rest }) => ((formData.tipo_recurso === 'Educacion') ? <>
                <SelectInput style={{marginRight: '20px'}} label="Categoria" source="categoria_educacion" choices={TipoCategoria} validate={[required()]} />
                <DateInput style={{marginRight: '20px'}} label="Fecha" source="fecha_hora" validate={[required()]} />
              </>
              : formData.tipo_recurso === 'Evento' ?
                <DateTimeInput style={{marginRight: '20px'}} label="Fecha y hora" source="fecha_hora" validate={[required()]} />
              : 
                <DateInput style={{marginRight: '20px'}} label="Fecha" source="fecha_hora" validate={[required()]} />
              )}
            </FormDataConsumer>
          </Grid>
        </Grid>
        <TextInput label="Título" source="titulo" fullWidth validate={[required()]} />
        <TextInput label="Copete" source="copete" minRows={2} multiline fullWidth validate={[required()]} />
        <ImageInput name="foto_home" aspect={1.32} label="foto home" />
        <FormDataConsumer>
				  {({ formData, ...rest }) => ((formData.tipo_recurso === 'Red_Derecho_Identidad' ?
            <TextInput 
              label="Contenido"
              source="contenido" 
              style={{overflow: "auto", height: "400px"}} 
              multiline 
              fullWidth
              validate={[jsonValidation]}             
            />
          :
            <TinyEditor
              source='contenido'
              validate={[required()]}
            />
          ))}  
        </FormDataConsumer>
        <TextInput label="Fuente" source="fuente" validate={[required()]} />
        <TextInput label="Autor" source="autor" validate={[required()]} />
        <Accordion fullWidth>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="documentos-content"
            id="panel-documentos"
          >
            <Typography>ADJUNTAR DOCUMENTOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source='documentos' label={false}>
              <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                <TextInput style={{ width: '580px' }} label='Título' source="titulo" />
                <TextInput
                  label='Tipo'
                  source='tipo'
                  defaultValue={'archivo'}
                  disabled
                />
                <FileInput
                  source={'url'}
                  aspect={1.45}
                  label='documento'
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>
      </SimpleForm>
    </Edit>
  );
}

const TipoRecurso = [
  { id: 'Accion', name: 'Acción' },
  { id: 'Evento', name: 'Evento' },
  { id: 'Educacion', name: 'Educación' },
  { id: 'Institucional', name: 'Institucional' },
  { id: 'Red_Derecho_Identidad', name: 'Red_Derecho_Identidad' }
];

const TipoCategoria = [
  { id: 'inicial-primario', name: 'Nivel inicial y primario' },
  { id: 'secundario-terciario', name: 'Nivel secundario y terciario' },
  { id: 'capacitacion-docente', name: 'Capacitación docente' },
  { id: 'investigacion', name: 'Investigación' }
];

const RecursoFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
    <SelectInput label="Tipo de recurso" source="tipo_recurso" choices={TipoRecurso} alwaysOn />
  </Filter>
);

const RecursoTitle = ({ record }) => {
  return <span>Recurso: {`"${record.titulo}"`}</span>;
};