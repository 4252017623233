import { MultimediaList, MultimediaCreate, MultimediaEdit } from './multimedias';
import icon from '@mui/icons-material/PermMedia';

const index = {
  list: MultimediaList,
  create: MultimediaCreate,
  edit: MultimediaEdit,
  icon: icon,
  options: { label: 'Multimedias' },
  name: "multimedia",
};

export default index;