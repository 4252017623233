import { TestimonioList, TestimonioCreate, TestimonioEdit } from './testimonios';
import icon from '@mui/icons-material/RecordVoiceOver';

const index = {
  list: TestimonioList,
  create: TestimonioCreate,
  edit: TestimonioEdit,
  icon: icon,
  options: { label: 'Testimonios' },
  name: "testimonio",
};

export default index;