import React, { Fragment } from 'react';
import {
	List,
	TextInput,
	Datagrid,
	TextField,
	Filter,
	Pagination,
	Create,
	Edit,
	BulkDeleteButton,
	SelectInput,
	DateInput,
	NumberInput,
	FormDataConsumer,
	TabbedForm,
	FormTab,
	useNotify,
	useRefresh,
	BooleanInput,
	maxLength,
	ArrayInput,
	SimpleFormIterator,
	required
} from 'react-admin';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageInput from '../ImageInput/ImageInput';
import { useDispatch } from 'react-redux';
import { handleSubmitFiles } from '../ImageInput/handleSubmitFiles';
import LugarReferenceInput from '../lugares/LugarReferenceInput';
import MilitanciaReferenceArrayInput from '../militancias/MilitanciaReferenceArrayInput';
import CcdReferenceArrayInput from '../lugares/CcdReferenceArrayInput';
import _ from 'lodash';

const CustomPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

const CustomBulkActionButtons = props => (
	<Fragment>
		<BulkDeleteButton {...props} />
	</Fragment>
);

export const FamiliarList = props => (
	<List
		{...props}
		bulkActionButtons={false}
		pagination={<CustomPagination />}
		filters={<CasoFilter />}>
		<Datagrid rowClick="edit">
			<TextField label="Apellidos" source="apellidos" />
			<TextField label="Nombres" source="nombres" />
			<TextField label="Estado" source="estado" />
		</Datagrid>
	</List>
);

export const FamiliarCreate = props => {
	const notify = useNotify();
	const dispatch = useDispatch();

	const handleSubmit = async data => {
		delete data.embarazo;
		parseDateRange(data);
		parseNumberRange(data);

		const files = [
			'web.imagen_url',
			'web_abuela.caso_abuela_imagen_url',
			'web_abuela.hero.abuela_hero_imagen_url',
			'web_abuela.hero.abuela_hero_imagen_url_mobile',
			'web_abuela.intro.abuela_intro_imagen_url',
			'web_abuela.abuela_linea_de_tiempo.abuela_linea_de_tiempo_imagen_url'
		];
		data.web_abuela?.bloque_videos?.videos.forEach((doc, index) => {
			files.push(`web_abuela.bloque_videos.videos[${index}].url`);
		});
		data.web_abuela?.bloque_imagenes?.imagenes.forEach((doc, index) => {
			files.push(`web_abuela.bloque_imagenes.imagenes[${index}].url`);
		});
		return handleSubmitFiles({ data, dispatch, notify, files });
	};

	return (
		<Create {...props} transform={handleSubmit}>
			<Formulario redirect={'/familiar'} />
		</Create>
	);
}

export const FamiliarEdit = props => {
	const notify = useNotify();
	const dispatch = useDispatch();
	const refresh = useRefresh();

	const onFailure = (error) => {
		notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
	};
	const onSuccess = () => {
		notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
		refresh();
	};

	const handleSubmit = async data => {
		delete data.embarazo;
		parseDateRange(data);
		parseNumberRange(data);

		const files = [
			'web.imagen_url',
			'web_abuela.caso_abuela_imagen_url',
			'web_abuela.hero.abuela_hero_imagen_url',
			'web_abuela.hero.abuela_hero_imagen_url_mobile',
			'web_abuela.intro.abuela_intro_imagen_url',
			'web_abuela.abuela_linea_de_tiempo.abuela_linea_de_tiempo_imagen_url'
		];
		data.web_abuela?.bloque_videos?.videos.forEach((doc, index) => {
			files.push(`web_abuela.bloque_videos.videos[${index}].url`);
		});
		data.web_abuela?.bloque_imagenes?.imagenes.forEach((doc, index) => {
			files.push(`web_abuela.bloque_imagenes.imagenes[${index}].url`);
		});
		return handleSubmitFiles({ data, dispatch, notify, files });
	};

	return (
		<Edit title={<CustomTitle />} {...props} onFailure={onFailure} onSuccess={onSuccess} mutationMode="pessimistic" transform={handleSubmit}>
			<Formulario />
		</Edit>
	);
};

const CasoFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Buscar" source="q" alwaysOn />
	</Filter>
);

const parseDateRange = data => {
	const datesRanges = [
		'parto.fecha_parto',
		'secuestro.fecha_secuestro',
		'secuestro.fecha_liberacion',
		'asesinato.fecha_asesinato',
		'asesinato.fecha_eaaf',
		'fecha_fallecimiento'
	];
	datesRanges.forEach(dateRange => {
		const range = _.get(data, dateRange);
		if (range && Array.isArray(range) && (range[0] === "" || range[1] === ""))
			_.set(data, dateRange, null);
	})
};

const parseNumberRange = data => {
	const range = data.parto?.meses_embarazo;
	if (range && Array.isArray(range) && (range.length < 2 || range[0] === null || range[1] === null))
		data.parto.meses_embarazo = null;
};

const Estado = [
	{ id: 'Desaparecidx', name: 'Desaparecidx' },
	{ id: 'Asesinadx', name: 'Asesinadx' },
	{ id: 'Identificadx por EAAF', name: 'Identificadx por EAAF' },
	{ id: 'Vivx', name: 'Vivx' },
	{ id: 'Fallecidx', name: 'Fallecidx' },
];

const TipoLugar = [
	{ id: 'Vía pública', name: 'Vía pública' },
	{ id: 'Domicilio', name: 'Domicilio' },
	{ id: 'Trabajo', name: 'Trabajo' },
];

const CustomTitle = ({ record }) => {
	return <span>Familiar {record?.apellidos ? `"${record.apellidos}, ${record.nombres || ''}"` : ''}</span>;
};

const Formulario = props => (
	<TabbedForm {...props} warnWhenUnsavedChanges={true}>
		<FormTab label="Familiar">
			<FormDataConsumer>
				{({ formData, ...rest }) => (
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid item xs={12}>
							<TextInput style={{ marginRight: '8px' }} label="Apellidos" source="apellidos" />
							<TextInput style={{ marginRight: '8px' }} label="Nombres" source="nombres" />
							<SelectInput label="Estado" source="estado" choices={Estado} allowEmpty />
						</Grid>
						<Grid item xs={12} md={3}>
							<LugarReferenceInput
								label="Lugar de nacimiento"
								source="id_lugar_nacimiento"
								reference="lugar"
								allowEmpty
								fullWidth
							/>
						</Grid>
						<Grid item xs={9}>
							<DateInput style={{ width: '240px', marginRight: '8px' }} label="Fecha de nacimiento" source="fecha_nacimiento" />
						</Grid>
						<Grid item xs={12} md={12}>
							<TextInput style={{ marginRight: '8px' }} label="Ocupación" source="ocupacion" />
							<NumberInput style={{ marginRight: '8px' }} label="Cantidad de hijos" source="cant_hijos" />
							<TextInput style={{ marginRight: '8px' }} label="Apodos familia y amigos" source="apodos_familia_amigos" />
							<TextInput label="Apodos militancia" source="apodos_militancia" />
						</Grid>
						<Grid item xs={12}>
							{formData.estado === 'Fallecidx' &&
								<>
									<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de fallecimiento. Desde:" source="fecha_fallecimiento[0]" />
									<DateInput parse={value => value} style={{ width: '240px' }} label="Fecha de fallecimiento. Hasta:" source="fecha_fallecimiento[1]" />
								</>
							}
						</Grid>
						<Grid item xs={12}>
							<MilitanciaReferenceArrayInput
								label="Militancias"
								source="militancias"
								reference="militancia"
							/>
						</Grid>
						{!formData.parto &&
							<Grid item xs={3}>
								<BooleanInput label="¿Embarazo?" source="embarazo" />
							</Grid>
						}
						{(formData.parto || formData.embarazo) && <>
							<Grid item xs={12}>
								<NumberInput min={1} max={9} style={{ width: '260px', marginRight: '8px' }} label="Meses de embarazo. Desde:" source="parto.meses_embarazo[0]" />
								<NumberInput min={1} max={9} style={{ width: '260px', marginRight: '8px' }} label="Meses de embarazo. Hasta:" source="parto.meses_embarazo[1]" />
							</Grid>
							<Grid item xs={12}>
								<DateInput parse={value => value} style={{ width: '260px', marginRight: '8px' }} label="Fecha de parto. Desde:" source="parto.fecha_parto[0]" />
								<DateInput parse={value => value} style={{ width: '260px', marginRight: '8px' }} label="Fecha de parto. Hasta:" source="parto.fecha_parto[1]" />
							</Grid>
						</>}
					</Grid>
				)}
			</FormDataConsumer>
		</FormTab>
		<FormTab label="Secuestro" path="secuestro">
			<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={1}
			>
				<Grid item xs={12} md={3}>
					<LugarReferenceInput
						label="Lugar de secuestro"
						source="secuestro.id_lugar"
						reference="lugar"
						allowEmpty
						fullWidth
					/>
				</Grid>
				<Grid item xs={9}>
					<SelectInput style={{ marginRight: '8px' }} label="Tipo de lugar" source="secuestro.tipo_lugar" choices={TipoLugar} allowEmpty />
				</Grid>
				<Grid item xs={12}>
					<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de secuestro. Desde:" source="secuestro.fecha_secuestro[0]" />
					<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de secuestro. Hasta:" source="secuestro.fecha_secuestro[1]" />
				</Grid>
				<Grid item xs={12}>
					<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de liberacion. Desde:" source="secuestro.fecha_liberacion[0]" />
					<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de liberacion. Hasta:" source="secuestro.fecha_liberacion[1]" />
				</Grid>
				<Grid item xs={12}>
					<CcdReferenceArrayInput
						label="CCDs"
						source="secuestro.ccds"
						reference="lugar"
					/>
				</Grid>
			</Grid>
		</FormTab>
		<FormDataConsumer>
			{({ formData, ...rest }) => ((formData.estado === 'Asesinadx' || formData.estado === 'Identificadx por EAAF') ?
				<FormTab {...rest} label="Asesinato" path="asesinato">
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid item xs={12} md={3}>
							<LugarReferenceInput
								label="Lugar de asesinato"
								source="asesinato.id_lugar_asesinato"
								reference="lugar"
								allowEmpty
								fullWidth
							/>
						</Grid>
						<Grid item xs={9}>
							<SelectInput style={{ marginRight: '8px' }} label="Tipo de lugar" source="asesinato.tipo_lugar" choices={TipoLugar} allowEmpty />
						</Grid>
						<Grid item xs={12}>
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de asesinato. Desde:" source="asesinato.fecha_asesinato[0]" />
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha de asesinato. Hasta:" source="asesinato.fecha_asesinato[1]" />
						</Grid>
						<Grid item xs={12}>
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha EAAF. Desde:" source="asesinato.fecha_eaaf[0]" />
							<DateInput parse={value => value} style={{ width: '240px', marginRight: '8px' }} label="Fecha EAAF. Hasta:" source="asesinato.fecha_eaaf[1]" />						</Grid>
						<Grid item xs={12} md={3}>
							<LugarReferenceInput
								label="Lugar de entierro"
								source="asesinato.id_lugar_entierro"
								reference="lugar"
								allowEmpty
								fullWidth
							/>
						</Grid>
					</Grid>
				</FormTab>
				:
				<Grid></Grid>
			)}
		</FormDataConsumer>

		<FormTab label="Web" path="web">
			<FormDataConsumer>
				{({ formData, ...rest }) => ((
					<>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={1}
						>
							<Grid item xs={12}>
								<BooleanInput label="¿Publicar familiar?" source="web.visible" />
							</Grid>
							<Grid item xs={12}>
								<ImageInput
									label="imagen del familiar"
									name="web.imagen_url"
									aspect={1}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextInput
									source="web.descripcion"
									label="Descripción"
									validate={maxLength(255)}
									fullWidth />
							</Grid>
						</Grid>
						{!formData.web_abuela &&
							<Grid item xs={12}>
								<BooleanInput style={{ marginTop: '20px' }} label="¿Es abuela?" source="web.esAbuela" />
							</Grid>
						}
						{(formData.web_abuela || formData.web?.esAbuela) && <>
							<Grid item xs={12}>
								<BooleanInput style={{ marginTop: '20px' }} label="¿Es abuela fundadora?" source="web_abuela.abuela_fundadora" />
							</Grid>
							<Grid item xs={12}>
								<NumberInput min={0} style={{ marginTop: '20px' }} label="Orden para el buscador" source="web_abuela.orden" />
							</Grid>
							<Accordion fullWidth>
								<AccordionSummary
									style={{ marginTop: '20px' }}
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelcasoabuela-content"
									id="panel-casoabuela"
								>
									<Typography>SECCION DE ABUELAS EN LA PÁGINA WEB DEL CASO</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<BooleanInput label="¿Publicar abuela?" source="web_abuela.caso_abuela_visible" />
										</Grid>
										<Grid item xs={12}>
											<ImageInput
												name="web_abuela.caso_abuela_imagen_url"
												aspect={1}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextInput
												disabled
												source="nombres"
												label="Nombres"
												style={{ marginRight: '8px' }}
											/>
											<TextInput
												disabled
												source="apellidos"
												label="Apellidos"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web_abuela.caso_abuela_texto"
												label="Texto"
												multiline
												minRows={2}
												validate={maxLength(1000)}
												fullWidth />
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panellineadetiempo-content"
									id="panel-lineadetiempo"
								>
									<Typography>LINEA DE TIEMPO</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<DateInput 
												style={{ width: '240px', marginRight: '8px' }} 
												label="Fecha" 
												source="web_abuela.abuela_linea_de_tiempo.abuela_linea_de_tiempo_fecha" 
											/>
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web_abuela.abuela_linea_de_tiempo.abuela_linea_de_tiempo_titulo"
												label="Título"
												validate={[maxLength(255)]}
												fullWidth />
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web_abuela.abuela_linea_de_tiempo.abuela_linea_de_tiempo_descripcion"
												label="Descripción"
												validate={[maxLength(255)]}
												fullWidth />
										</Grid>
										<Grid item xs={12}>
											<ImageInput
												name="web_abuela.abuela_linea_de_tiempo.abuela_linea_de_tiempo_imagen_url"
												aspect={1.46}
											/>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</>}
						{(formData.web_abuela || formData.web?.esAbuela) && <>
							<Typography variant='h5' style={{ marginTop: '40px', marginBottom: '20px' }}>
								PÁGINA WEB DE LA ABUELA:
							</Typography>
							<Grid item xs={12}>
								<BooleanInput label="¿Publicar abuela?" source="web_abuela.abuela_visible" />
							</Grid>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelabuelas-inicio"
									id="panel-abuelas-inicio"
								>
									<Typography>INICIO</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<ImageInput
												name="web_abuela.hero.abuela_hero_imagen_url"
												aspect={1.85}
												label='imagen web'
											/>
										</Grid>
										<Grid item xs={12}>
											<ImageInput
												name="web_abuela.hero.abuela_hero_imagen_url_mobile"
												aspect={0.56}
												label='imagen celular'
											/>
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web_abuela.hero.abuela_hero_frase"
												label="Frase"
												validate={maxLength(255)}
												fullWidth />
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelabuelas-intro"
									id="panel-abuelasintro"
								>
									<Typography>INTRODUCCIÓN</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<ImageInput
												name="web_abuela.intro.abuela_intro_imagen_url"
												aspect={1}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web_abuela.intro.abuela_intro_imagen_pie"
												label="Pie de foto"
												validate={maxLength(255)}
												fullWidth />
										</Grid>
										<Grid item xs={12}>
											<TextInput
												source="web_abuela.intro.abuela_intro_descripcion"
												label="Descripción"
												multiline
												minRows={2}
												validate={maxLength(1000)}
												fullWidth />
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelvideos-content"
									id="panel-videos"
								>
									<Typography>BLOQUE DE VIDEOS</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<BooleanInput label="¿Visible?" source="web_abuela.bloque_videos.bloque_videos_visible" />
										</Grid>
										{formData.web_abuela?.bloque_videos?.bloque_videos_visible &&
											<>
												<Grid item xs={12}>
													<TextInput
														source="web_abuela.bloque_videos.bloque_videos_texto"
														label="Texto"
														validate={[required(), maxLength(255)]}
														fullWidth />
												</Grid>
												<Grid item xs={12}>
													<TextInput
														source="web_abuela.bloque_videos.bloque_videos_firma"
														label="Firma"
														validate={[required(), maxLength(255)]}
														fullWidth />
												</Grid>
												<ArrayInput source='web_abuela.bloque_videos.videos' label={false}>
													<SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
														<TextInput style={{ width: '580px' }} label='Título' source="titulo" />
														<TextInput
															label='Tipo'
															source='tipo'
															defaultValue={'video'}
															disabled
														/>
														<TextInput
															style={{ width: '580px' }}
															label='URL del video'
															validate={[required(), maxLength(255)]}
															source='url'
														/>
													</SimpleFormIterator>
												</ArrayInput>
											</>}
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelfrase-content"
									id="panel-frase"
								>
									<Typography>FRASE</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<BooleanInput label="¿Visible?" source="web_abuela.bloque_frase.bloque_frase_visible" />
										</Grid>
										{formData.web_abuela?.bloque_frase?.bloque_frase_visible &&
											<>
												<Grid item xs={12}>
													<TextInput
														source="web_abuela.bloque_frase.bloque_frase_texto"
														label="Texto"
														validate={[required(), maxLength(255)]}
														fullWidth />
												</Grid>
												<Grid item xs={12}>
													<TextInput
														source="web_abuela.bloque_frase.bloque_frase_firma"
														label="Autor"
														validate={[required(), maxLength(255)]}
														fullWidth />
												</Grid>
											</>}
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion fullWidth>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panelimagenes-content"
									id="panel-imagenes"
								>
									<Typography>BLOQUE DE IMAGENES</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={1}
									>
										<Grid item xs={12}>
											<BooleanInput label="¿Visible?" source="web_abuela.bloque_imagenes.bloque_imagenes_visible" />
										</Grid>
										{formData.web_abuela?.bloque_imagenes?.bloque_imagenes_visible &&
											<>
												<Grid item xs={12}>
													<TextInput
														source="web_abuela.bloque_imagenes.bloque_imagenes_texto"
														label="Texto"
														validate={[required(), maxLength(255)]}
														fullWidth />
												</Grid>
												<Grid item xs={12}>
													<TextInput
														source="web_abuela.bloque_imagenes.bloque_imagenes_firma"
														label="Firma"
														validate={[required(), maxLength(255)]}
														fullWidth />
												</Grid>
												<ArrayInput source='web_abuela.bloque_imagenes.imagenes' label={false}>
													<SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
														<TextInput
															style={{ width: '580px' }}
															label='Título'
															validate={[required(), maxLength(255)]}
															source="titulo"
														/>
														<TextInput
															label='Tipo'
															source='tipo'
															defaultValue={'imagen'}
															disabled
														/>
														<ImageInput
															source={'url'}
															aspect={1.45}
															label='imagen'
															validate={[required()]}
														/>
													</SimpleFormIterator>
												</ArrayInput>
											</>}
									</Grid>
								</AccordionDetails>
							</Accordion>
						</>}
					</>
				))}
			</FormDataConsumer>
		</FormTab>
	</TabbedForm>
);